*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.main_div{
    width: 100%;
    height: 100vh;
    background: rgb(23, 23, 55);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}


.center_div{
    width: 25%;
    height: 80vh;
    padding: 0 20px;
    background-color: white;
    box-shadow: 5px 5px 25px -5px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
   
}

h1{
    color: white;
    background: transparent;
    background-color: rgb(110, 58, 159);
    padding: 6px 0 2px 0;
    margin-bottom: 10px;
    box-shadow: 5px 5px 15px -5px rgba(0, 0, 0, 0.3);
}

input{
    text-align: center;
    height: 30px;
    top: 10px;
    border: none;
    background: transparent;
    font-size: 20px;
    font-weight: 500;
    width: 60%;
    border-bottom: 2px solid rgb(110, 58, 159);
    outline: none;

}
button{
    min-height: 50px;
    width: 50px;
    border-radius: 50%;
    border-color: transparent;
    background-color:rgb(110, 58, 159) ;
    color: white;
    font-size: 40px;
    border: none;
    outline: none;
    margin-left: 10px;
    box-shadow:5px 5px 15px -5px rgba(0, 0, 0, 0.3);
}
button:hover{
    cursor: pointer;
    background-color: green;
}
ol{
    margin-top: 30px;
}

ol li{
    padding-left: 0px;
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    min-height: 40px;
    display: flex;
    align-items: center;
    color: rgb(110, 58, 159);
    text-transform: capitalize;
}


/* List component css  */
.todo_style{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}
.todo_style .fa-times{
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(110, 58, 159);
    border-radius: 50%;
    margin: 0 15px 0 35px;
    color: white;
}
.todo_style .fa-times:hover{
    background-color: red;
}

@media screen and  (max-width:1024px) {
    .center_div{
        width:80%;}
}